<i18n>
{
	ja : {
		doc_link : "ドキュメント",
		support_link : "サポートサイト"
	},
	en  : {
		doc_link : "Documents",
		support_link : "Support Site"
	}
}
</i18n>

<template>
	<section class="shadow dark:bg-gray-900">
		<nav
			class="
				container
				p-6
				mx-auto
				lg:flex lg:justify-between lg:items-center
			"
		>
			<div class="flex items-center justify-between">
				<a
					href="/"
					class="
						inline-flex
						items-center
						text-black-800 text-2xl
						md:text-3xl
						font-bold
						gap-2.5
					"
					aria-label="logo"
				>
					<img
						v-if="isDark"
						class="w-40 text-indigo-500"
						:src="require('../assets/qmonus_notm.svg')"
					/>
					<img
						v-else
						class="w-40 text-indigo-500"
						:src="require('../assets/qmonus_notm-inverse.svg')"
					/>
				</a>

				<!-- Mobile menu button -->
				<div class="flex lg:hidden">
					<button
						@click="isOpen = !isOpen"
						type="button"
						class="
							text-gray-500
							dark:text-gray-200
							hover:text-gray-600
							dark:hover:text-gray-400
							focus:outline-none focus:text-gray-600
							dark:focus:text-gray-400
						"
						aria-label="toggle menu"
					>
						<svg
							v-show="!isOpen"
							xmlns="http://www.w3.org/2000/svg"
							class="w-6 h-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M4 8h16M4 16h16"
							/>
						</svg>

						<svg
							v-show="isOpen"
							xmlns="http://www.w3.org/2000/svg"
							class="w-6 h-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
			</div>

			<div
				:class="[
					isOpen
						? 'translate-x-0 opacity-100 '
						: 'opacity-0 -translate-x-full',
				]"
				class="
					absolute
					inset-x-0
					z-20
					w-full
					px-6
					py-4
					transition-all
					shadow-md
					lg:bg-transparent lg:dark:bg-transparent lg:shadow-none
					dark:bg-gray-900
					lg:mt-0
					lg:p-0
					lg:top-0
					lg:relative
					lg:w-auto
					lg:opacity-100
					lg:translate-x-0
					lg:flex
					lg:items-center
					bg-white
				"
			>
				<div
					class="
						flex flex-col
						space-y-4
						lg:mt-0 lg:flex-row lg:-px-8 lg:space-y-0
					"
				>
					<a
						class="
							py-2
							px-3
							dark:bg-gray-900
							hover:bg-gray-100
							dark:text-white
							dark:hover:bg-gray-800
							dark:ring-gray-700
							rounded-lg
						"
						href="https://support.qmonus.net"
						>{{ $t("support_link") }}</a
					>
				</div>

				<div
					class="
						space-y-4
						lg:mt-0 lg:flex-row lg:-px-8 lg:space-y-0 lg:mx-3
						py-2
						px-3
						dark:bg-gray-900
						hover:bg-gray-100
						dark:text-white
						dark:hover:bg-gray-800
						dark:ring-gray-700
						rounded-lg
					"
				>
					<a class="btn" href="https://developer.qmonus.net">{{
						$t("doc_link")
					}}</a>
				</div>

				<div class="flex justify-center mt-6 lg:flex lg:mt-0 lg:ml-3">
					<select
						v-model="$root.$i18n.locale"
						class="
							text-sm text-gray-900
							bg-gray-50
							rounded-lg
							border border-gray-300
							focus:ring-blue-500 focus:border-blue-500
							dark:bg-gray-700
							dark:border-gray-600
							dark:placeholder-gray-400
							dark:text-white
							dark:focus:ring-blue-500
							dark:focus:border-blue-500
						"
					>
						<option value="en">en</option>
						<option value="ja">ja</option>
					</select>

					<a
						href="#"
						@click.prevent="$emit('toggleTheme')"
						class="
							mx-2
							text-gray-600
							transition-colors
							duration-300
							transform
							dark:text-gray-300
							hover:text-gray-500
							dark:hover:text-gray-300
						"
					>
						<svg
							v-if="isDark"
							class="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
							></path>
						</svg>
						<svg
							v-else
							class="w-5 h-5 fill-current"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
							></path>
						</svg>
					</a>
				</div>
			</div>
		</nav>
	</section>
</template>

<script>
export default {
	props: {
		isDark: Boolean,
	},
	data() {
		return {
			isOpen: false,
		};
	},
};
</script>
