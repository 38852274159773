<i18n>
{
	en : {
		caption:"Platform to accelerate DevOps for cloud-native microservices",
		try_btn : "Try It"
	},
	ja : {
		caption:"クラウドネイティブなマイクロサービスのDevOpsを加速させるプラットフォーム",
		try_btn : "試してみる"
	}
}
</i18n>

<template>
	<section>
		<div class="container px-6 py-16 mx-auto min-h-[60vh]">
			<div class="items-center lg:flex lg:mt-12">
				<div class="w-full lg:w-1/2">
					<div class="lg:max-w-2lg">
						<h1
							class="
								text-2xl
								sm:text-4xl
								font-bold
								text-blue-600
								dark:text-gray-300
								my-10
								text-center
							"
						>
							{{ $t("caption") }}
						</h1>

						<div class="mx-10 my-10">
							<img
								v-if="isDark"
								class="w-full max-h-[500px]"
								:src="require('../assets/qmonus_tm.svg')"
							/>
							<img
								v-else
								class="w-full max-h-[500px]"
								:src="
									require('../assets/qmonus_tm-inverse.svg')
								"
							/>
						</div>

						<div class="mx-10 my-10 pt-3 lg:pt-6 text-center">
							<a
								class="
									bg-blue-600
									text-white
									font-semibold
									tracking-wider
									py-4
									px-10
									text-lg
									rounded
								"
								href="./#contact"
							>
								{{ $t("try_btn") }}
							</a>
						</div>
					</div>
				</div>

				<div
					class="
						flex
						items-center
						justify-center
						w-full
						mt-6
						lg:mt-0 lg:w-1/2
						hidden
						lg:block
						text-center
					"
				>
					<img
						v-if="isDark"
						class="max-h-[350px]"
						style="margin: auto; opacity: 0.9"
						:src="require('../assets/qmonus_logo.svg')"
					/>

					<img
						v-else
						class="max-h-[350px]"
						style="margin: auto; opacity: 0.8"
						:src="require('../assets/qmonus_logo-inverse.svg')"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		isDark: Boolean,
	},
};
</script>
