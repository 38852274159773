<i18n>
</i18n>

<template>
	<footer class="dark:bg-gray-900">
		<div class="container p-6 mx-auto">
			<div class="lg:flex mt-12">
				<div class="w-full -mx-6 lg:w-2/5">
					<div class="px-6">
						<div>
							<img
								v-if="isDark"
								class="w-40"
								:src="require('../assets/qmonus_notm.svg')"
							/>
							<img
								v-else
								class="w-40"
								:src="
									require('../assets/qmonus_notm-inverse.svg')
								"
							/>
						</div>

						<p
							class="
								max-w-sm
								mt-2
								text-gray-500
								dark:text-gray-400
							"
						>
							Maximize Your Developer Experience
						</p>
					</div>
				</div>

				<div class="mt-6 lg:mt-0 lg:flex-1">
					<p class="text-gray-500">
						A place (field) that serves as a means (tool) that
						continues to provide the highest performance for the
						user at the same time, but also serves as a store and a
						share. It is a brand that continues to change toward the
						future, changing shapes and multiplying while responding
						to changing environments.
					</p>
				</div>
			</div>

			<hr class="h-px my-6 bg-gray-200 border-none dark:bg-gray-700" />

			<div>
				<p class="text-center text-gray-500 dark:text-gray-400">
					Copyright © 2014-{{ new Date().getUTCFullYear() }} NTT
					Communications
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	props: {
		isDark: Boolean,
	},
};
</script>
