<i18n>
{
	ja : {
		msg:[
			"Qmonus(クモナス)は、NTTコミュニケーションズが内製開発したクラウドネイティブアプリケーションの開発およびアプリケーションのデリバリー、運用を高度化するためのプラットフォームです。アプリケーションのDevOpsに必要なツールセットをプラットフォームとして提供し、ベストプラクティスに基づくアプリケーションのDevOpsに貢献します。",
			"クラウド上でマイクロサービスを高速に開発できるソフトウェアフレームワーク「Qmonus SDK」、継続的なソフトウェアデリバリーを自動化するCI/CDプラットフォーム「Qmonus Value Stream」、Qmonusはこれら2つのプロダクトで構成されます。 ",
		],
		sdk : {
			title : "マイクロサービスアプリケーションを高速に開発するためのソフトウェアフレームワーク ",
			desc : "ソフトウエアフレームワークが提供する様々な機能を組み合わせることでマイクロサービスを簡単に設計することができます。ビジネスロジックを実装するだけで高速にアプリケーションを開発することができます。 ",
		},
		vs  : {
			title : "ソフトウェアデリバリーを自動化するためのCI/CDプラットフォーム",
			desc : "クラウドネイティブアダプターと呼ばれるアーキテクチャのベストプラクティスを提供します。クラウドネイティブアダプターを利用してCI/CDパイプラインを構築することで、アプリケーションを素早くデプロイすることができます。",
		}
	},
	en : {
		msg:[
			"Qmonus is a platform developed in-house by NTT Communications for advanced cloud-native application development, delivery and operation.It provides a platform with the necessary toolset for application DevOps and contributes to application DevOps based on best practices.",
			"Qmonus consists of two products: Qmonus SDK, a software framework for rapid microservice development in the cloud, and Qmonus Value Stream, a CI/CD platform that automates continuous software delivery.",
		],
		sdk : {
			title : "Software framework for rapid development of microservice applications",
			desc : "It provides various functional counties that are necessary for developing microservices, which can be combined to easily design applications. This allows developers to develop applications at high speed by simply implementing business logic.",
		},
		vs  : {
			title : "CI/CD platform for automating software delivery",
			desc : "It provides best practices for cloud-native architecture, called 'Cloud Native Adapter'. Developers can use these 'Cloud Native Adapter' to build CI/CD pipelines to deploy applications quickly.",
		}
	}
}
</i18n>

<template>
	<section>
		<div class="container px-6 py-10 mx-auto">
			<h1
				class="
					text-3xl
					font-semibold
					text-center text-gray-800
					lg:text-4xl
					dark:text-white
				"
			>
				<br />
				<span v-if="$i18n.locale == 'ja'">
					<span class="text-blue-500">Qmonus</span> とは何か
				</span>
				<span v-else>
					What is <span class="text-blue-500">Qmonus</span>
				</span>
			</h1>
			<Sep />

			<div
				class="max-w-4xl mx-auto mt-6 text-gray-500 dark:text-gray-300"
			>
				<template v-for="i in [0, 1, 2, 3, 4]" :key="i">
					<span v-if="$te(`msg[${i}]`)">
						{{ $t(`msg[${i}]`) }}<br />
					</span>
				</template>
			</div>

			<div
				class="
					grid grid-cols-1
					gap-8
					mt-8
					xl:mt-12 xl:gap-12
					md:grid-cols-2
				"
			>
				<div
					class="
						p-6
						border
						rounded-xl
						border-r-gray-200
						dark:border-gray-700
						hover:border-transparent
						group
						hover:bg-white
						dark:hover:bg-gray-800
					"
				>
					<div class="md:flex md:items-start md:-mx-4">
						<span
							class="
								inline-block
								p-2
								bg-blue-500
								rounded-xl
								md:mx-4
								dark:bg-blue-500
							"
						>
							<img
								class="
									w-6
									h-6
									md:w-10
									md:h-10
									md:max-w-[50px]
									md:max-h-[50px]
								"
								style="margin: auto"
								src="../assets/sdk.svg"
							/>
						</span>

						<div class="mt-4 md:mx-4 md:mt-0">
							<h1
								class="
									text-2xl
									font-medium
									text-gray-700
									capitalize
									dark:text-white
								"
							>
								Qmonus <span class="font-bold">SDK</span>
							</h1>

							<p class="mt-3 text-gray-500 dark:text-gray-300">
								{{ $t("sdk.desc") }}
							</p>
						</div>
					</div>
				</div>

				<div
					class="
						p-6
						border
						rounded-xl
						border-r-gray-200
						dark:border-gray-700
						hover:bg-white
						dark:hover:bg-gray-800
					"
				>
					<div class="md:flex md:items-start md:-mx-4">
						<span
							class="
								inline-block
								p-2
								bg-blue-500
								rounded-xl
								md:mx-4
								dark:bg-blue-500
							"
						>
							<img
								class="
									w-6
									h-6
									md:w-10
									md:h-10
									md:max-w-[50px]
									md:max-h-[50px]
								"
								style="margin: auto"
								src="../assets/vs.svg"
							/>
						</span>

						<div class="mt-4 md:mx-4 md:mt-0">
							<h1
								class="
									text-2xl
									font-medium
									text-gray-700
									capitalize
									dark:text-white
								"
							>
								Qmonus
								<span class="font-bold">Value Stream</span>
							</h1>

							<p class="mt-3 text-gray-500 dark:text-gray-300">
								{{ $t("vs.desc") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
