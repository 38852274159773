<template >
	<!-- メニューバー -->
	<Header
		:isDark="isDark"
		class="bg-white dark:bg-gray-900"
		@toggleTheme="toggleTheme"
	/>
	<hr class="h-px bg-gray-200 border-none dark:bg-gray-700" />
	<!-- メインタイトル -->
	<Hero :isDark="isDark" class="dark:bg-gray-900" />
	<!-- What is Qmonus -->
	<What class="dark:bg-gray-900" />
	<!-- Why Qmonus -->
	<Why class="dark:bg-gray-900" />
	<!-- Contact -->
	<Contact id="contact" class="dark:bg-gray-900" />
	<hr class="h-px bg-gray-200 border-none dark:bg-gray-700" />
	<!-- フッター -->
	<Footer :isDark="isDark" class="bg-white dark:bg-gray-900" />
</template>

<script>
import Header from "./contents/Header";
import Hero from "./contents/Hero";
import What from "./contents/What";
import Why from "./contents/Why";
import Contact from "./contents/Contact";
import Footer from "./contents/Footer";

export default {
	components: {
		Header,
		Hero,
		What,
		Why,
		Contact,
		Footer,
	},
	mounted() {
		// window.document.documentElement.classList.add("dark");
	},

	data() {
		return {
			toggle: false,
		};
	},

	computed: {
		isDark() {
			this.toggle;
			return localStorage.theme === "dark";
		},
	},

	methods: {
		toggleTheme() {
			if (localStorage.theme === "dark") {
				document.documentElement.classList.remove("dark");
				localStorage.theme = "light";
			} else {
				document.documentElement.classList.add("dark");
				localStorage.theme = "dark";
			}
			this.toggle = !this.toggle;
		},
	},
};
</script>
