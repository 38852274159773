<i18n>
{
	ja : {
		msg:[
			"アプリケーションの提供価値を高めるためには、利用者の要望にタイムリーに対応する、テクノロジーのトレンドの変化に対応する、セキュリティの脅威に対応する、など継続的なDevOpsが欠かせません。NTTコミュニケーションズは、Qmonusを活用してマイクロサービス化、API化を進め、継続的なDevOpsを具現化しています。",
			"NTTグループの様々なサービスがこのような思想のもと、マイクロサービス化、API化が進むことで、サービスを柔軟に組合せることができ、さらには最適化が容易となれば、コグニティブ・ファウンデーション構想の具現化につながると考えています。",
			"我々はQmonusが、IOWNの構成要素の一つであるコグニティブ・ファウンデーションを体現するベストプラクティスの一つと考えています。",
		],
		detail_btn : "詳しく知る",
		iown : "IOWN(Innovative Optical & Wireless Network)とは、スマートな世界を実現する最先端の光関連技術および情報処理技術を活用した未来のコミュニケーション基盤​です。",
		cogf : "Cognitive Foundation（コグニティブ・ファウンデーション）とは、クラウドやネットワークサービスに加え、様々なICTリソースを含めた配備や構成の最適化、構築・設定および管理・運用を一元的に実施し、ライフサイクルマネジメントの完全自動化・自律化を目指す構想​です。",
		qmonus : "ビジネスの変化に素早く適用できるマイクロサービスアーキテクチャを導入し、継続的なソフトウェアデリバリーよるDevOpsを実践することで、価値のあるアプリケーション開発にシフトしましょう。Qmonusはそれらを実現するトータルソリューションです。",
	},
	en : {
		msg:[
			"NTT Communications is leveraging Qmonus to realize continuous DevOps by moving toward microservices, APIs, and other forms of DevOps. NTT Communications is leveraging Qmonus to realize continuous DevOps through the shift to microservices and APIs.",
			"We believe that if NTT Group's various services can be flexibly combined and optimized through the increased use of microservices and APIs based on this concept, it will lead to the realization of the Cognitive Foundation concept.",
			"We consider Qmonus to be one of the best practices that embody the Cognitive Foundation, one of the building blocks of the IOWN.",
		],
		detail_btn : "Learn More",
		iown : "IOWN (Innovative Optical & Wireless Network) is a future communication infrastructure that utilizes cutting-edge optical-related and information processing technologies to realize a smart world.",
		cogf : "The Cognitive Foundation is a concept that aims for fully automated and autonomous lifecycle management by centrally optimizing, building, configuring, managing, and operating the deployment and configuration of various ICT resources, in addition to cloud and network services. The concept aims to achieve fully automated and autonomous lifecycle management.",
		qmonus : "Qmonus is a total solution that enables you to shift to high-value application development by adopting a microservices architecture that can be quickly adapted to business changes, and by practicing DevOps with continuous software delivery.",
	}
}
</i18n>

<template>
	<section>
		<div class="container px-6 py-10 mx-auto">
			<h1
				class="
					text-3xl
					font-semibold
					text-center text-gray-800
					capitalize
					lg:text-4xl
					dark:text-white
				"
			>
				<br />

				<span v-if="$i18n.locale == 'ja'">
					なぜ <span class="text-blue-500">Qmonus</span> なのか
				</span>
				<span v-else>
					Why <span class="text-blue-500">Qmonus</span>
				</span>
			</h1>

			<Sep />

			<div
				class="max-w-4xl mx-auto mt-6 text-gray-500 dark:text-gray-300"
			>
				<template v-for="i in [0, 1, 2, 3, 4]" :key="i">
					<span v-if="$te(`msg[${i}]`)">
						{{ $t(`msg[${i}]`) }}<br />
					</span>
				</template>
			</div>

			<section
				class="
					lg:bg-white lg:dark:bg-gray-800
					mt-8
					space-y-8
					lg:mt-12 lg:shadow-lg lg:rounded-lg
				"
				v-for="v in videos"
				:key="v.key"
			>
				<section class="lg:flex lg:items-center">
					<div class="lg:w-1/2">
						<div
							class="
								px-4
								text-2xl
								font-semibold
								text-gray-800
								capitalize
								lg:text-3xl
								dark:text-white
							"
						>
							<span v-if="$i18n.locale == 'ja'">
								<span class="text-blue-500">{{ v.title }}</span>
								について知る
							</span>
							<span v-else>
								Learn about
								<span class="text-blue-500">{{ v.title }}</span>
							</span>
						</div>

						<p
							class="
								text-sm
								tracking-wider
								text-gray-500
								px-4
								mt-3
								my-3
								dark:text-blue-400
							"
						>
							{{ $t(v.key) }}
						</p>
						<div class="mt-6 my-3 px-4" v-show="v.link">
							<a
								:href="v.link"
								class="
									px-5
									py-2
									font-semibold
									text-gray-100
									transition-colors
									duration-300
									transform
									rounded-md
									bg-gray-900
									hover:bg-gray-700
									dark:bg-gray-300 dark:text-black
								"
								>{{ $t("detail_btn") }}</a
							>
						</div>
					</div>
					<div class="lg:w-1/2 lg:mt-0">
						<!-- <iframe
							class="
								object-cover
								w-full
								h-64
								rounded-lg
								lg:rounded-l-none
								md:h-96
								min-w-full
								h-64
								md:h-[350px]
								overflow-hidden
							"
							:src="v.path"
							frameborder="0"
						/> -->

						<video
							class="
								object-cover
								w-full
								h-64
								rounded-lg
								lg:rounded-l-none
								md:h-96
								min-w-full
								h-64
								md:h-[350px]
								overflow-hidden
							"
							:poster="require('../assets/bg.jpg')"
							controls
							:src="v.path"
							frameborder="0"
						/>
					</div>
				</section>
			</section>

			<hr class="h-px my-12 bg-gray-200 border-none dark:bg-gray-700" />
		</div>
	</section>
</template>

<script>
export default {
	computed: {
		videos() {
			// const isJa = this.$i18n.locale == "ja";
			return [
				{
					key: "iown",
					title: "IOWN®",
					path: "/01_IOWN.mp4",
					link: "https://www.rd.ntt/iown/",
				},
				{
					key: "cogf",
					title: "Cognitive Foundation®",
					path: "/02_Concept.mp4",
					link: "https://www.rd.ntt/iown/0004.html",
				},
				{
					key: "qmonus",
					title: "Qmonus®",
					path: "/03_Qmonus.mp4",
				},
			];
		},
	},
};
</script>
