export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform to accelerate DevOps for cloud-native microservices"])},
        "try_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try It"])}
      },
      "ja": {
        "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドネイティブなマイクロサービスのDevOpsを加速させるプラットフォーム"])},
        "try_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["試してみる"])}
      }
    }
  })
}
