import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import './index.css'

import App from './App.vue'
import sep from './components/sep'

if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
	document.documentElement.classList.add('dark')
	localStorage.theme = 'dark'
} else {
	document.documentElement.classList.remove('dark')
	localStorage.theme = 'light'
}

const i18n = createI18n({
	locale: navigator.language.split('-')[0],
})

createApp(App).component("Sep", sep).use(i18n).mount('#app')
