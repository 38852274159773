<i18n>
{
	ja : {
		msg : "Qmonusのご利用にはNTTコミュニケーションズとのご契約が必要になります。まずは試してみたい方に向けてはSandbox環境も無料で提供しています。サポートサイトからご相談ください。いずれもNTTグループ内の利用に限ります。",
		sandbox_btn : "Sandboxで試してみる",
		contact_btn : "サポートサイトで相談する",
		notice : "Sandboxのご利用には、以下の条件に承諾いただく必要があります。",
		notes : [ /* 10件まで自動反映 */
			{
				title:"一部機能制限があります",
				detail:"利用できる機能に一部制限があります。またネットワーク制約により接続できる外部システムが制限されます。"
			},
			{
				title:"クラウドリソースは共用です",
				detail:"Sandbox利用ユーザでクラウドリソースを共用しています。秘匿性・機密性のある情報は環境に保存しないでください。"
			},
			{
				title:"利用できる期間は１ヶ月です",
				detail:"環境利用状況によっては延長が可能な場合もあります。長期間の検証が必要な場合はご相談ください。"
			}
		],
		guide : "詳細はSandbox利用案内を確認してください。",
		download_btn : "Sandbox利用案内をダウンロードする",
	},
	en : {
		msg : "Qmonus requires a contract with NTT Communications. For those who would like to try it out first, we offer a free Sandbox environment. Please contact us from the support site. Both of these services are limited to use within the NTT Group.",
		sandbox_btn : "Try Qmonus Sandbox",
		contact_btn : "Consult Our Support Site",
		notice : "To use Sandbox, you must agree to the following",
		notes : [ /* 10件まで自動反映 */
			{
				title:"Some functionality is limited.",
				detail:"There are some limitations on the functions available. Also, network restrictions limit the number of external systems that can be connected."
			},
			{
				title:"Cloud resources are shared.",
				detail:"Cloud resources are shared by users. Do not store any confidential or sensitive information in the environment."
			},
			{
				title:"Available for 1 month",
				detail:"Extension may be possible depending on Sandbox usage. Please contact us if you need a longer verification period."
			}
		],
		guide : "Please check the Sandbox User Guide for details.",
		download_btn : "Download Sandbox User Guide",
	}
}
</i18n>

<template>
	<section class="">
		<div class="container px-6 py-10 mx-auto">
			<h1
				class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"
			>
				<br />

				<span v-if="$i18n.locale == 'ja'">
					<span class="text-blue-500">Qmonus</span> を使いたい
				</span>
				<span v-else> To use <span class="text-blue-500">Qmonus</span> </span>
			</h1>
			<Sep />

			<div
				class="max-w-4xl mx-auto mt-6 text-center text-gray-500 dark:text-gray-300"
			>
				{{ $t("msg") }}
			</div>

			<!-- btns -->
			<div
				class="mt-3 container flex flex-col items-center px-4 py-3 mx-auto text-center"
			>
				<div class="mt-6 sm:-mx-2">
					<!-- <div class="inline-flex w-full sm:w-auto sm:mx-2">
						<a
							href="mailto:iowncf-qmonus-sandbox@ml.ntt.com"
							class="inline-flex items-center justify-center w-full px-5 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
						>
							{{ $t("sandbox_btn") }} &nbsp;

							<svg
								class="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
								></path>
							</svg>
						</a>
					</div> -->

					<div class="inline-flex w-full mt-4 sm:w-auto sm:mx-2 sm:mt-0">
						<a
							href="https://support.qmonus.net"
							class="inline-flex items-center justify-center w-full px-5 py-2 text-gray-700 transition-colors duration-150 transform bg-white border border-gray-200 dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800 dark:ring-gray-700 rounded-lg sm:w-auto focus:ring focus:ring-gray-200 focus:ring-opacity-80"
						>
							{{ $t("contact_btn") }} &nbsp;

							<svg
								class="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M9 5l7 7-7 7"
								></path>
							</svg>
						</a>
					</div>
				</div>
			</div>

			<div
				class="mt-12 text-xl font-semibold tracking-tight text-gray-700 sm:text-xl dark:text-white text-center"
			>
				{{ $t("notice") }}
			</div>

			<div
				class="mt-3 space-y-3"
				v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
				:key="i"
			>
				<div
					v-if="$te(`notes[${i}]`)"
					class="p-3 bg-gray-200 rounded-lg dark:bg-gray-800 max-w-2xl mx-auto"
				>
					<h1 class="font-semibold text-gray-700 dark:text-white">
						{{ $t(`notes[${i}].title`) }}
					</h1>

					<p class="mt-3 ml-3 text-sm text-gray-500 dark:text-gray-300">
						{{ $t(`notes[${i}].detail`) }}
					</p>
				</div>
			</div>

			<div
				class="tracking-tight text-gray-700 dark:text-white mt-6 text-center"
			>
				{{ $t("guide") }}
			</div>

			<div
				class="container flex flex-col items-center px-4 mx-auto text-center"
			>
				<div class="mt-3">
					<div class="inline-flex w-full sm:w-auto sm:mx-2">
						<a
							href="./sandbox_user_guide.pdf"
							download="qmonus_sandbox_user_guide.pdf"
							class="inline-flex items-center justify-center w-full px-5 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
						>
							{{ $t("download_btn") }}
						</a>
					</div>
				</div>
			</div>
			<div class="h-px my-12">&nbsp;</div>
		</div>
	</section>
</template>
