export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ja": {
        "msg": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アプリケーションの提供価値を高めるためには、利用者の要望にタイムリーに対応する、テクノロジーのトレンドの変化に対応する、セキュリティの脅威に対応する、など継続的なDevOpsが欠かせません。NTTコミュニケーションズは、Qmonusを活用してマイクロサービス化、API化を進め、継続的なDevOpsを具現化しています。"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NTTグループの様々なサービスがこのような思想のもと、マイクロサービス化、API化が進むことで、サービスを柔軟に組合せることができ、さらには最適化が容易となれば、コグニティブ・ファウンデーション構想の具現化につながると考えています。"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我々はQmonusが、IOWNの構成要素の一つであるコグニティブ・ファウンデーションを体現するベストプラクティスの一つと考えています。"])},
          
        ],
        "detail_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳しく知る"])},
        "iown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IOWN(Innovative Optical & Wireless Network)とは、スマートな世界を実現する最先端の光関連技術および情報処理技術を活用した未来のコミュニケーション基盤​です。"])},
        "cogf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognitive Foundation（コグニティブ・ファウンデーション）とは、クラウドやネットワークサービスに加え、様々なICTリソースを含めた配備や構成の最適化、構築・設定および管理・運用を一元的に実施し、ライフサイクルマネジメントの完全自動化・自律化を目指す構想​です。"])},
        "qmonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスの変化に素早く適用できるマイクロサービスアーキテクチャを導入し、継続的なソフトウェアデリバリーよるDevOpsを実践することで、価値のあるアプリケーション開発にシフトしましょう。Qmonusはそれらを実現するトータルソリューションです。"])}
      },
      "en": {
        "msg": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NTT Communications is leveraging Qmonus to realize continuous DevOps by moving toward microservices, APIs, and other forms of DevOps. NTT Communications is leveraging Qmonus to realize continuous DevOps through the shift to microservices and APIs."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe that if NTT Group's various services can be flexibly combined and optimized through the increased use of microservices and APIs based on this concept, it will lead to the realization of the Cognitive Foundation concept."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We consider Qmonus to be one of the best practices that embody the Cognitive Foundation, one of the building blocks of the IOWN."])},
          
        ],
        "detail_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
        "iown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IOWN (Innovative Optical & Wireless Network) is a future communication infrastructure that utilizes cutting-edge optical-related and information processing technologies to realize a smart world."])},
        "cogf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cognitive Foundation is a concept that aims for fully automated and autonomous lifecycle management by centrally optimizing, building, configuring, managing, and operating the deployment and configuration of various ICT resources, in addition to cloud and network services. The concept aims to achieve fully automated and autonomous lifecycle management."])},
        "qmonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qmonus is a total solution that enables you to shift to high-value application development by adopting a microservices architecture that can be quickly adapted to business changes, and by practicing DevOps with continuous software delivery."])}
      }
    }
  })
}
