export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ja": {
        "msg": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qmonus(クモナス)は、NTTコミュニケーションズが内製開発したクラウドネイティブアプリケーションの開発およびアプリケーションのデリバリー、運用を高度化するためのプラットフォームです。アプリケーションのDevOpsに必要なツールセットをプラットフォームとして提供し、ベストプラクティスに基づくアプリケーションのDevOpsに貢献します。"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウド上でマイクロサービスを高速に開発できるソフトウェアフレームワーク「Qmonus SDK」、継続的なソフトウェアデリバリーを自動化するCI/CDプラットフォーム「Qmonus Value Stream」、Qmonusはこれら2つのプロダクトで構成されます。 "])},
          
        ],
        "sdk": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイクロサービスアプリケーションを高速に開発するためのソフトウェアフレームワーク "])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソフトウエアフレームワークが提供する様々な機能を組み合わせることでマイクロサービスを簡単に設計することができます。ビジネスロジックを実装するだけで高速にアプリケーションを開発することができます。 "])}
        },
        "vs": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソフトウェアデリバリーを自動化するためのCI/CDプラットフォーム"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドネイティブアダプターと呼ばれるアーキテクチャのベストプラクティスを提供します。クラウドネイティブアダプターを利用してCI/CDパイプラインを構築することで、アプリケーションを素早くデプロイすることができます。"])}
        }
      },
      "en": {
        "msg": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qmonus is a platform developed in-house by NTT Communications for advanced cloud-native application development, delivery and operation.It provides a platform with the necessary toolset for application DevOps and contributes to application DevOps based on best practices."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qmonus consists of two products: Qmonus SDK, a software framework for rapid microservice development in the cloud, and Qmonus Value Stream, a CI/CD platform that automates continuous software delivery."])},
          
        ],
        "sdk": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software framework for rapid development of microservice applications"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It provides various functional counties that are necessary for developing microservices, which can be combined to easily design applications. This allows developers to develop applications at high speed by simply implementing business logic."])}
        },
        "vs": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CI/CD platform for automating software delivery"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It provides best practices for cloud-native architecture, called 'Cloud Native Adapter'. Developers can use these 'Cloud Native Adapter' to build CI/CD pipelines to deploy applications quickly."])}
        }
      }
    }
  })
}
