export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ja": {
        "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qmonusのご利用にはNTTコミュニケーションズとのご契約が必要になります。まずは試してみたい方に向けてはSandbox環境も無料で提供しています。サポートサイトからご相談ください。いずれもNTTグループ内の利用に限ります。"])},
        "sandbox_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandboxで試してみる"])},
        "contact_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポートサイトで相談する"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandboxのご利用には、以下の条件に承諾いただく必要があります。"])},
        "notes": [
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部機能制限があります"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用できる機能に一部制限があります。またネットワーク制約により接続できる外部システムが制限されます。"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドリソースは共用です"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox利用ユーザでクラウドリソースを共用しています。秘匿性・機密性のある情報は環境に保存しないでください。"])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用できる期間は１ヶ月です"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["環境利用状況によっては延長が可能な場合もあります。長期間の検証が必要な場合はご相談ください。"])}
          }
        ],
        "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細はSandbox利用案内を確認してください。"])},
        "download_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox利用案内をダウンロードする"])}
      },
      "en": {
        "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qmonus requires a contract with NTT Communications. For those who would like to try it out first, we offer a free Sandbox environment. Please contact us from the support site. Both of these services are limited to use within the NTT Group."])},
        "sandbox_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Qmonus Sandbox"])},
        "contact_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult Our Support Site"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use Sandbox, you must agree to the following"])},
        "notes": [
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some functionality is limited."])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are some limitations on the functions available. Also, network restrictions limit the number of external systems that can be connected."])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud resources are shared."])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud resources are shared by users. Do not store any confidential or sensitive information in the environment."])}
          },
          {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for 1 month"])},
            "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension may be possible depending on Sandbox usage. Please contact us if you need a longer verification period."])}
          }
        ],
        "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the Sandbox User Guide for details."])},
        "download_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Sandbox User Guide"])}
      }
    }
  })
}
